import React from 'react';
import Button from "../Button/Button";
import CheckIcon from "../../assets/icons/checkIcon";
import {approvePost, rejectPost} from "../../api/adminApi";
import {toast} from "react-toastify";
import CancelIcon from "../../assets/icons/CancelIcon";
import {useRecoilState, useSetRecoilState} from "recoil";
import {currentProfile} from "../../store/userDataStates";
import {fullScreenLoaderState} from "../../store/modalStates";
import {useNavigate} from "react-router-dom";

const AdminButtons = ({postData}) => {

    const [profile] = useRecoilState(currentProfile);
    const setLoader = useSetRecoilState(fullScreenLoaderState)
    const navigate = useNavigate();

    return (
        <>
            {
                profile?.role?.name === "ROLE_ADMIN" ?
                    <div className="ADMIN_ROW">
                        <h2>ADMIN FUNCTIONS</h2>
                        <div className="ADMIN__BUTTONS">
                            <Button iconRight={<CheckIcon/>} onClick={() => {
                                setLoader(true)
                                approvePost(postData).then((res) => {
                                    toast('Approved', {type: 'success', autoClose: 3000})
                                    navigate('/admin')
                                }).finally(() => {
                                    setLoader(false)
                                })
                            }} clx={'APPROVE'}
                                    title={'APPROVE'}/>
                            <Button iconRight={<CancelIcon/>} onClick={() => {
                                setLoader(true)
                                rejectPost(postData).then((res) => {
                                    toast('Rejected', {type: 'error', autoClose: 3000})
                                    navigate('/admin')
                                }).finally(() => {
                                    setLoader(false)
                                })
                            }} clx={'REJECT'}
                                    title={'REJECT'}/>
                        </div>
                    </div>
                    : ''
            }

        </>
    );
};

export default AdminButtons;