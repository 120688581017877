import React, {useEffect, useState} from 'react';
import './ChoosePromotion.scss';
import {getPromotionTypes, postAdvertisementApi} from "../../../api/postApi";
import ButtonBack from "../../../components/ButtonBack/ButtonBack";
import Button from "../../../components/Button/Button";
import {useTranslation} from "react-i18next";
import CheckIcon from "../../../assets/icons/checkIcon";
import ConfirmModal from "../../../shared/ConfirmModal/ConfirmModal";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {uploadFile} from "../../../api/fileApi";
import {useSetRecoilState} from "recoil";
import {fullScreenLoaderState} from "../../../store/modalStates";

const ChoosePromotion = ({setPage, changePostKeyAndValue, postData}) => {

    const [promotionTypes, setPromotionTypes] = useState([]);
    const {t, i18n} = useTranslation();
    const [confirmModal, setConfirmModal] = useState(false);
    const navigate = useNavigate();
    const setLoader = useSetRecoilState(fullScreenLoaderState);

    useEffect(() => {
        setLoader(true)
        getPromotionTypes().then((res) => {
            setPromotionTypes(res?.data)
            setLoader(false)
        }).catch(() => {
            setLoader(false)
        })
    }, []);

    const existTranslation = (key) => {
        return i18n.exists(key)
    };
    console.log(postData)

    const postAdvertisement = () => {
        setLoader(true)
        if (postData?.images?.length) {
            uploadFile(postData?.images).then((res) => {
                postAdvertisementApi({
                    ...postData,
                    images: res?.data,
                }).then((res) => {
                    toast('Пост опубликован!', {type: 'success', autoClose: 3000});
                    navigate('/profile')
                    setLoader(false)
                }).catch((err) => {
                    setLoader(false)
                    toast('Error', {type: 'error', autoClose: 3000})
                })
            }).catch((err) => {
                setLoader(false)
                toast(err?.message, {type: 'error', autoClose: 3000})
            })
        } else {
            postAdvertisementApi(postData).then((res) => {
                toast('Пост опубликован!', {type: 'success', autoClose: 3000});
                navigate('/profile')
                setLoader(false)
            }).catch((err) => {
                setLoader(false)
                toast('Error', {type: 'error', autoClose: 3000})
            })

        }
        document.querySelector('body').style.overflow = 'auto'
    };

    return (
        <main className="ChoosePromotion">
            <div className="container">
                <div className="ChoosePromotion__content">

                    <ButtonBack customAction={() => setPage(0)}/>

                    <h2 className="header4">Выберите план поста</h2>

                    <div className="ChoosePromotion__promotions">
                        {
                            promotionTypes?.slice(0, 1)?.map((promotion) => (
                                <div key={promotion?.id} className={`Promotion ${promotion?.name}`}>
                                    <span>{promotion?.name}</span>
                                    <h2>{promotion?.price ? `${promotion?.price}$ / ${t('main.paymentTypes.weekly')}` : 'Free'}</h2>
                                    <div className="Promotion__benefits">

                                        <div className="Promotion__benefits_row">
                                            <CheckIcon color={'var(--primary-color)'}/>
                                            <span>{t(`main.promotionBenefits.${promotion?.name}.first`)}</span>
                                        </div>

                                        <div className="Promotion__benefits_row">
                                            <CheckIcon color={'var(--primary-color)'}/>
                                            <span>
                                                {t(`main.promotionBenefits.${promotion?.name}.second`)}
                                            </span>
                                        </div>

                                        {
                                            existTranslation(`main.promotionBenefits.${promotion?.name}.third`) ?
                                                <div className="Promotion__benefits_row">
                                                    <CheckIcon color={'var(--primary-color)'}/>
                                                    <span>
                                                {t(`main.promotionBenefits.${promotion?.name}.third`)}
                                            </span>
                                                </div>
                                                : ''
                                        }

                                        {
                                            existTranslation(`main.promotionBenefits.${promotion?.name}.fourth`) ?
                                                <div className="Promotion__benefits_row">
                                                    <CheckIcon color={'var(--primary-color)'}/>
                                                    <span>
                                                {t(`main.promotionBenefits.${promotion?.name}.fourth`)}
                                            </span>
                                                </div>
                                                : ''
                                        }


                                    </div>
                                    <Button onClick={() => {
                                        changePostKeyAndValue(promotion, 'promotionTypeId');
                                        setConfirmModal(true)
                                    }} full title={'Выбрать'}/>
                                </div>
                            ))
                        }
                    </div>


                    <ConfirmModal action={postAdvertisement}
                                  title={<span>
                                      <span className="bodyXL body400">{t("main.actions.postWithThisPromotion")} - </span>
                                      <span className="bodyXL body600">{postData?.promotionTypeId?.name}</span>
                                  </span>}
                                  confirmModal={confirmModal} setConfirmModal={setConfirmModal}/>
                </div>
            </div>

        </main>
    );
};

export default ChoosePromotion;