export const postDataTemplate = {
    "categoryId": 1,
    "price": null,
    "title": null,
    "description": null,
    "status": "UNDER_REVIEW",
    "locationId": null,
    "promotionTypeId": null,
    "priceTypeId": null,
    "images": [],
    "workType": null,
    "otherInfo": null
}

export const defaultLocation = {
    "id": null,
    "createdDate": "2024-01-27T07:14:59.288Z",
    "modifiedDate": "2024-01-27T07:14:59.288Z",
    "city": "America",
    "cityAscii": "America",
    "stateId": "All",
    "stateName": "All America",
    "countyFips": 0,
    "countyName": "string",
    "lat": 0,
    "lng": 0,
    "population": 0,
    "density": 0,
    "source": "string",
    "military": true,
    "incorporated": true,
    "timezone": "string",
    "ranking": 0,
    "zips": "string",
    "locationId": 0,
    "isDeleted": false
}

export const sxodimTypes = ['event', 'place'];
export const homeKitchenTypes = ['delivery', 'pickup'];

export const truckTypes = ['courses', 'drivers']
export const cdlType = ['CDL', 'NON CDL'];
export const truckMilesType = ['otr', 'regional', 'local', 'dedicated'];