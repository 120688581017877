import React from 'react';
import './CategoryCard.scss';
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

const CategoryCard = ({cardData, action, short}) => {

    const navigate = useNavigate();
    const {t} = useTranslation();

    return (
        <div style={{background: cardData?.description}} onClick={async () => {
            navigate(`/marketplace/${cardData?.categoryName}`)
            action && action()
        }} className={`CategoryCard ${short ? 'CategoryCardShort' : ''}`}>

            <div className="CategoryCard__left">
                <img src={cardData?.imgUrl} alt={cardData.title}/>
            </div>

            <div className="CategoryCard__right">
                <h3 className="bodyXL body500">{t(`main.Categories.${cardData?.categoryName}`)}</h3>
                {
                    short ? '' :
                        <span className="bodyM">{t(`main.CategoriesDescription.${cardData?.categoryName}`)}</span>

                }
            </div>

        </div>
    );
};

export default CategoryCard;