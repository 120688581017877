import imageCompression from "browser-image-compression";
import {instance} from "./instance";

export const handleCompareImage = async (imageFile) => {
    const options = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 1200,
        useWebWorker: true,
    }
    try {
        return await imageCompression(imageFile, options)
    } catch (error) {
        return false
    }
}

export const uploadFile = async (file) => {
    const formData = new FormData();
    if (file.length) {
        file?.map((newFile) => {
            formData.append('files', newFile)
            return newFile
        })
    } else {
        formData.append('files', file)
    }
    return instance.post('storage/uploadFile', formData, {
        'Content-type': 'multipart/form-data',
    }).then((res) => {
        return res
    })
}

export const deleteFile = (fileUrl) => {
    return instance.delete(`storage/deleteFile?fileUrls=${fileUrl}`)
}
