import React, {useState} from 'react';
import './SupportUs.scss';
import LanguageSwitch from "../../components/LanguageSwitch/LanguageSwitch";
import Button from "../../components/Button/Button";
import {useTranslation} from "react-i18next";

const SupportUs = () => {

    const [something, setSomething] = useState(false)
    const {t} = useTranslation();

    if (localStorage.getItem('firstVisit')) {
        return null
    }

    const submitForm = (e) => {
        e.preventDefault();
        localStorage.setItem('firstVisit', 'no')
        setSomething(prevState => !prevState);
        console.log(something)
    }

    return (
        <div className="SupportUsWindow">
            <div className="SupportUsWindowBlur"></div>
            <form onSubmit={submitForm}>
                <label>
                    <h2>{t("main.FirstTime")}</h2>
                    <LanguageSwitch/>
                </label>
                <label>
                    <span>{t("main.SupportUsByPost")}</span>
                </label>
                <label className="imageLabel">
                    <img
                        src="https://site.obs2go.com/ajax/do_download/obs2go_documentation/138825126_docman4.png/docman4.png"
                        alt=""/>
                </label>
                <label>
                    <Button active full title={t("main.Ok")}/>
                </label>
            </form>
        </div>
    );
};

export default SupportUs;