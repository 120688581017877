import React, {useEffect} from 'react';
import {useRecoilState} from "recoil";
import {currentProfile} from "../../store/userDataStates";
import ButtonBack from "../../components/ButtonBack/ButtonBack";
import {useNavigate} from "react-router-dom";
import './ADMIN.scss';
import MarketplaceSection from "../Marketplace/MarketplaceSection";
import {categoriesAllList} from "../../store/postStates";

const Admin = () => {

    const [profile] = useRecoilState(currentProfile);
    const navigate = useNavigate();
    const [categories] = useRecoilState(categoriesAllList);

    useEffect(() => {
        if (profile?.role?.name !== 'ROLE_ADMIN') {
            navigate('/')
        }
    }, []);

    return (
        <main className="ADMIN">
            <div className="container">
                <div className="MarketplaceMain__content">

                    <ButtonBack/>

                    {
                        categories?.map((category, idx) => (
                            <MarketplaceSection admin={true} idx={idx} category={category}/>
                        ))
                    }

                </div>
            </div>
        </main>
    );
};

export default Admin;