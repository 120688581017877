import React, {useEffect} from 'react';
import './ChooseCategory.scss';
import {useRecoilState} from "recoil";
import {chooseCategoryState} from "../../store/searchState";
import {categoriesAllList} from "../../store/postStates";
import CategoryCard from "../../components/CategoryCard/CategoryCard";
import {useTranslation} from "react-i18next";


const ChooseCategory = () => {

    const [chooseCategory, setChooseCategory] = useRecoilState(chooseCategoryState);
    const [categories] = useRecoilState(categoriesAllList);
    const {t} = useTranslation();

    useEffect(() => {
        let body = document.querySelector('body')
        if (chooseCategory) {
            body.style.overflow = 'hidden'
            const elements = document.querySelectorAll('*');
            elements.forEach((element) => {
                if (typeof element.blur === 'function') {
                    element.blur();
                }
            });
        } else {
            body.style.overflow = 'auto'
        }
    }, [chooseCategory]);


    return (
        chooseCategory && <div className="ChooseCategory">
            <div onClick={() => setChooseCategory(false)} className="ChooseCategory__blur"></div>
            <div className="ChooseCategory__form">
                <h2 className='header4'>{t("main.Warnings.whatAreYouLooking")}</h2>
                <div className="ChooseCategory__form_buttons">
                    {categories.map((but) => (
                        <CategoryCard action={() => setChooseCategory(false)} short cardData={but}/>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ChooseCategory;