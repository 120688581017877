import {instance} from "./instance";

export const getAdminMainPageContent = async (setLoaders) => {
    setLoaders({
        others: true,
        jobs: true,
        apartments: true,
        cars: true,
        business: true
    })
    let mainPage = {
        others: [],
        jobs: [],
        apartments: [],
        cars: [],
        business: []
    }
    await instance.get(`/adv/filter-pageable?status=UNDER_REVIEW&categoryId=1&page=0&size=20&sort=createdDate,DESC`, {
        headers: null
    }).then((res) => {
        mainPage = {...mainPage, apartments: res?.data?.content}
        setLoaders({
            others: true,
            jobs: true,
            apartments: false,
            cars: true,
            business: true
        })
    })
    await instance.get(`/adv/filter-pageable?status=UNDER_REVIEW&categoryId=2&page=0&size=20&sort=createdDate,DESC`, {
        headers: null
    }).then((res) => {
        mainPage = {...mainPage, jobs: res?.data?.content}
        setLoaders({
            others: true,
            jobs: false,
            apartments: false,
            cars: true,
            business: true
        })
    })
    await instance.get(`/adv/filter-pageable?status=UNDER_REVIEW&categoryId=3&page=0&size=20&sort=createdDate,DESC`, {
        headers: null
    }).then((res) => {
        mainPage = {...mainPage, cars: res?.data?.content}
        setLoaders({
            others: true,
            jobs: false,
            apartments: false,
            cars: false,
            business: true
        })
    })
    await instance.get(`/adv/filter-pageable?status=UNDER_REVIEW&categoryId=4&page=0&size=20&sort=createdDate,DESC`, {
        headers: null
    }).then((res) => {
        mainPage = {...mainPage, business: res?.data?.content}
        setLoaders({
            others: true,
            jobs: false,
            apartments: false,
            cars: false,
            business: false
        })
    })
    await instance.get(`/adv/filter-pageable?status=UNDER_REVIEW&categoryId=5&page=0&size=20&sort=createdDate,DESC`, {
        headers: null
    }).then((res) => {
        mainPage = {...mainPage, others: res?.data?.content}
        setLoaders({
            others: false,
            jobs: false,
            apartments: false,
            cars: false,
            business: false
        })
    })
    return mainPage
}


export const approvePost = async (postData) => {
    return await instance.put(`/adv/${postData?.id}`, {
        ...postData,
        status: 'APPROVED'
    })
}

export const rejectPost = async (postData) => {
    return await instance.put(`/adv/${postData?.id}`, {
        ...postData,
        status: 'REJECTED'
    })
}