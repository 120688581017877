import React, {useEffect, useState} from 'react';
import CustomInput from "../../components/CustomInput/CustomInput";
import {useRecoilState, useSetRecoilState} from "recoil";
import {chooseCategoryState, searchInputState} from "../../store/searchState";
import './Marketplace.scss';
import ButtonBack from "../../components/ButtonBack/ButtonBack";
import {categoriesAllList} from "../../store/postStates";
import MarketplaceSection from "./MarketplaceSection";
import CategoryCard from "../../components/CategoryCard/CategoryCard";
import {useTranslation} from "react-i18next";
import {instance} from "../../api/instance";
import {userCurrentCity} from "../../store/userDataStates";

const Marketplace = () => {


    const [searchState, setSearchState] = useRecoilState(searchInputState);
    const setCategory = useSetRecoilState(chooseCategoryState)
    const [categories] = useRecoilState(categoriesAllList);
    const {t} = useTranslation();
    const [pageData, setPageData] = useState({});
    const [location] = useRecoilState(userCurrentCity)
    const submitSearch = (e) => {
        setCategory(true)
    }

    useEffect(() => {
        let url = `/adv/filter-pageable?status=APPROVED&page=0&size=0`
        if (location?.id) {
            url += `&locationId=${location?.id}`
        }
        instance(url, {
            headers: null
        }).then((res) => {
            setPageData(res?.data)
        })
    }, [location]);

    return (
        <main className="MarketplaceMain">

            <div className="container">

                <div className="MarketplaceMain__content">

                    <ButtonBack/>

                    <h2 className="header4">
                        <span>{t("main.navigation.total")}</span>
                        <span className="body600 primary_color"> {pageData?.totalElements || 0} </span>
                        <span>{t("main.navigation.posts")}</span>
                    </h2>

                    <CustomInput value={searchState} clx={'MainSearchForm'}
                                 onChange={(e) => setSearchState(e.target.value)}
                                 onSearch={(e) => submitSearch(e)} width={'100%'}
                                 placeholder={`${t('main.actions.search')}...`}/>

                    <div className="MarketplaceMain__categories">
                        {
                            categories?.map((category) => (
                                <CategoryCard key={category?.title} cardData={category}/>
                            ))
                        }
                    </div>

                    {categories?.map((category, idx) => (
                        <MarketplaceSection category={category} idx={idx}/>
                    ))}

                </div>

            </div>

        </main>
    );
};

export default Marketplace;