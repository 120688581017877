import {handleCompareImage} from "../api/fileApi";

export const getLocationById = (cardData, CitiesDb) => {
    for (let i = 0; i < CitiesDb?.length; i++) {
        if (+CitiesDb[i]?.id === +cardData?.location?.id) {
            return CitiesDb[i]
        }
    }
}

export const navigateToPost = async (cardData, navigate) => {
    if (window.location.href.includes('profile')) {
        navigate(`/profile/${cardData?.id}`)
    } else if (window.location.href.includes('admin')) {
        navigate(`/marketplace/details/${cardData?.id}`)
    } else {
        window.open(`/marketplace/details/${cardData?.id}`, '_blank')
    }
}

export const getPaymentType = (t, cardData) => {
    if (cardData?.priceType?.name === 'oneTime') {
        return ''
    }
    return `/ ${t(`main.paymentTypes.${cardData?.priceType?.name}`)}`
}


export const getADate = (cardData) => {
    return cardData?.createdDate?.split('T')[0].split('-').reverse().join('.')
}

export const returnHeightProfile = (profileData, start) => {
    let height = start || 0
    if (profileData?.telegram) {
        height += 40
    }
    if (profileData?.phoneNumber) {
        height += 40
    }
    if (profileData?.whatsapp) {
        height += 40
    }
    return height
}


export const uploadFilesForm = async (setLoader, changePostKeyAndValue, e) => {
    setLoader(true)
    changePostKeyAndValue([], 'images');
    let resizedFiles = Array.from(e.target.files)?.slice(0, 10).map((file) => {
        return handleCompareImage(file).then((res) => {
            return res
        })
    })
    await Promise.all(resizedFiles)
        .then((results) => {
            changePostKeyAndValue(results, 'images')
            setLoader(false)
        })
        .catch((error) => {
            console.error('Error:', error);
        });
}

export const getTextWithParagraphs = (jsonString) => {
    const paragraphs = jsonString.split('\n').map((paragraph, index) => (
        <p key={index}>{paragraph}</p>
    ));
    return paragraphs;
}

export const getClassCards = (category) => {
    switch (category) {
        case 'jobs':
            return 'MarketplaceMain__jobs'
        case 'sxodim':
            return 'MarketplaceMain__apartments'
        case 'others':
            return 'MarketplaceMain__apartments'
        case 'cars':
            return 'MarketplaceMain__apartments'
        case 'apartments':
            return 'MarketplaceMain__apartments'
        case 'business':
            return 'MarketplaceMain__apartments'
        case 'questions':
            return 'MarketplaceMain__jobs'
        default:
            return 'MarketplaceMain__apartments'
    }
}